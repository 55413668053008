<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add UpComing Course
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
          <b-col md="12">
            <b-col md="12">
              <b-form-group
                label="Course Type"
                label-for="blog-edit-course"
                class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules="">
                  <v-select
                    id="blog-edit-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.type"
                    label="name"
                    :options="typeList"
                    :reduce="(val) => val.id"
                    @input="getCourseSchedule(addCourseForm.type)" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="addCourseForm.type !== 'dedicated'">
              <b-form-group
                label="Select Category"
                label-for="blog-edit-course"
                class="mb-2">
                <validation-provider #default="{ errors }" name="cate" rules="">
                  <v-select
                    id="blog-edit-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.category_id"
                    label="type"
                    :options="categoriesList"
                    :reduce="(val) => val.id"
                    @input="getCoursesByCategory(addCourseForm.category_id)" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              v-if="addCourseForm.type == 'dedicated' && dedicated">
              <b-form-group
                label="Select Course"
                label-for="blog-edit-course"
                class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules="">
                  <v-select
                    id="blog-edit-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.course"
                    label="name"
                    :options="coursesList"
                   
                    :reduce="(val) => val.id"
                    >
                    <template #no-options="{ search, searching, loading }">
                      No data ...
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12" v-if="!dedicated">
              <b-form-group
                label="Select Course"
                label-for="blog-edit-course"
                class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules="">
                  <v-select
                    id="blog-edit-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.course"
                    label="name"
                    :options="coursesList"
                    :reduce="(val) => val.id"
                    @input="getscheduleByCourseOnline('',addCourseForm.course)"
                    >
                    <template #no-options="{ search, searching, loading }">
                      No data ...
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              v-if="
                addCourseForm.type !== 'dedicated' &&
                addCourseForm.type !== 'online'
              ">
              <b-form-group
                label="Select City"
                label-for="blog-edit-course"
                class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules="">
                  <v-select
                    id="blog-edit-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.city"
                    label="name"
                    :options="citiesList"
                    :reduce="(val) => val.id"
                    @input="
                      getscheduleByCourse(
                        addCourseForm.city,
                        addCourseForm.course
                      )
                    " />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Select Schedule Date"
                label-for="blog-edit-course"
                class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules="">
                  <v-select
                    id="blog-edit-category"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="addCourseForm.schedule"
                    label="date"
                    :options="scheduleList"
                    :reduce="(val) => val.id">
                    <template #no-options="{ search, searching, loading }">
                      No data ...
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="In Slider Order"
                label-for="blog-edit-title"
                class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="In Slider Order"
                  rules="required">
                  <b-form-input
                    id="blog-edit-title"
                    v-model="addCourseForm.slider" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";

export default {
  setup(props, { emit }) {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const Tabimage = ref("");
    const coursesList = ref([]);
    const categoriesList = ref([]);
    const scheduleList = ref([]);
    const citiesList = ref([]);
    const dedicated = ref(false);
    store.dispatch("categories/CategoryList").then((response) => {
      categoriesList.value = response.data;
    });
    const getCourseSchedule = (id) => {
      console.log("id", id);
      if (id == "dedicated") {
        dedicated.value = true;
        store.dispatch("homepage/getScheduleDate").then((response) => {
          console.log("scheduleList", response);
          scheduleList.value = response.data.data;
        });
        store.dispatch("homepage/getOtherCourse", { id }).then((response) => {
          console.log("response", response.data);
          coursesList.value = response.data.data;
        });
      }
      if (id == "online") {
        dedicated.value = false;
        store
          .dispatch("courses/getAllCoursesOnline")
          .then((response) => {
            console.log("response", response.data);
            coursesList.value = response.data.data;
          });
      }
      if (id == "classic") {
        dedicated.value = false;
        store
          .dispatch("courses/getAllClassical")
          .then((response) => {
            console.log("response", response.data);
            coursesList.value = response.data.data;
          });
      }
    };
    const getscheduleByCourse = (cityid, courseid) => {
      
      store
        .dispatch("homepage/getscheduleByCourse", { cityid, courseid })
        .then((response) => {
          console.log("", response.data);
          console.log("z", response.data);
          scheduleList.value = response.data;
        });
    };
    const getscheduleByCourseOnline = (cityid, courseid) => {
      console.log("poo")
      if(addCourseForm.type=='online'){
      store
        .dispatch("homepage/getscheduleByCourse", { cityid, courseid })
        .then((response) => {
          console.log("", response.data);
          console.log("z", response.data);
          scheduleList.value = response.data;
        });
      }
    };
    const typeList = [
      {
        name: "Classic",
        id: "classic",
      },
      {
        name: "Online",
        id: "online",
      },

      {
        name: "Dedicated",
        id: "dedicated",
      },
    ];

    const addCourseForm = reactive({
      category_id: "",
      type: "",
      course: "",
      city: "",
      schedule: "",
      slider: "",
    });
    const getCoursesByCategory = (id) => {
      let online=null 
      if(addCourseForm.type=='online'){
        online=1
      }
      else{
        online=0
      }
      store
      .dispatch("courses/getAllCoursesList", {
        "filter[category_id]": id,
          "filter[online]":online
        })
        .then((response) => {
          console.log("response", response.data);
          coursesList.value = response.data;
        });
    };
    store.dispatch("cities/cititesList").then((response) => {
      console.log("cititesList", response.data);
      citiesList.value = response.data;
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
    const addCourseFormvalidate = ref();

    const save = () => {
      console.log(addCourseForm);
      const formData = new FormData();

      addCourseFormvalidate.value.validate().then((success) => {
        if (success) {
          formData.append("schedule_id", addCourseForm.schedule);
          formData.append("type", addCourseForm.type);
          formData.append("in_slider_order", addCourseForm.slider);
          // formData.append("type", addCourseForm.type);
          // formData.append("category_id", addCourseForm.category_id);
          // formData.append("in_slider_order", addCourseForm.slider);
          // formData.append("schedule_id", addCourseForm.schedule);
          // formData.append("type", addCourseForm.type);

          console.log("formData", formData);
          store
            .dispatch("homepage/addUpComingCourse", formData)
            .then((response) => {
              addCourseForm.schedule=''
              addCourseForm.type=''
              addCourseForm.slider=''
              addCourseForm.course=''
              addCourseForm.category_id=''
              addCourseForm.city=''
              addCourseForm.slider=''
              
              emit("add-course");
              Vue.swal({
                title: " Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });

          console.log("alt_header_image", addCourseForm);
        }
      });
    };
    return {
      refInputEl,
      dedicated,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      typeList,
      categoriesList,
      getCoursesByCategory,
      citiesList,
      getCourseSchedule,
      scheduleList,
      getscheduleByCourseOnline,
      getscheduleByCourse,
      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
